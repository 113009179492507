<template>
  <div class="signup-button">
    <p class="signup-button__text">{{ signupMessageText }}</p>
    <v-btn class="signup-button__btn" depressed @click="isSample ? $emit('show-warning') :signup()">Googleでアカウント作成</v-btn>
  </div>
</template>

<script>
export default {
  computed: {
    /**
     * @return {Object} Signupのテキスト情報
     */
    signupTextInfos () {
      return this.$store.getters['settingSignups/settingSignups']
    },
    /**
     * @return {String} Signupのメッセージテキスト
     */
    signupMessageText () {
      const message = this.signupTextInfos.messages ? this.signupTextInfos.messages.texts[2] : '1分で簡単に入会できます！'
      return this.exemption ? 'こちらからアカウント作成に進んでください' : message
    },
    /**
     * @return {Object} 例外情報
     */
    exemption () {
      return this.$store.getters['exemptions/exemption']
    },
    /**
     * @return {Boolean} サンプルかどうか
     */
    isSample () {
      return process.env.VUE_APP_IS_SAMPLE.toLowerCase() === 'true'
    }
  },
  methods: {
    /**
     * アカウント作成
     */
    signup () {
      this.$store.commit('setSubmitting', true)

      // サインイン情報をセッションに保存
      // オブジェクトをそのまま保存すると文字列になるのでstringifyを使用
      sessionStorage.setItem('isSignupUser', true)
      if (this.exemption) sessionStorage.setItem('exemption', JSON.stringify(this.exemption))

      // Auth認証
      // 続きの処理はonAuthで実施
      this.$store.dispatch('auth/signin', 'google')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.signup-button {
  text-align: center;
  &__text {
    margin-bottom: 0;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2rem;
  }
  &__btn {
    display: block;
    margin: $unit_size*2 auto 0;
    font-size: 1.4rem;
    font-weight: bold;
    color: $white_color;
    border-radius: 15px;
    &.v-btn:not(.v-btn--round).v-size--default {
      width: auto;
      max-width: 100%;
      height: auto;
      padding: 15px 20px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $concept_color;
    }
    & ::v-deep .v-btn__content {
      display: block;
      min-width: 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: none;
      white-space: nowrap;
    }
  }
}
</style>
