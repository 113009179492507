<template>
  <ul class="signup-warning">
    <li v-if="this.isSetGroupSetting && !this.isDiscordLinkage" class="signup-warning__text">{{this.groupName}}への参加申請の承認には3営業日ほどお時間をいただく場合がございます。</li>
    <li class="signup-warning__text">本サービスを利用される前に必ず
      <router-link class="signup-warning__text__link" :to="{ name: 'terms' }">利用規約</router-link>、
      <router-link class="signup-warning__text__link" :to="{ name: 'policy' }">プライバシーポリシー</router-link>、
      <router-link class="signup-warning__text__link" :to="{ name: 'law' }">特定商取引法に基づく表記</router-link>
      をお読みください。本サービスにご登録頂いた時点で、これらに同意したものとみなします。</li>
  </ul>
</template>

<script>
export default {
  props: {
    // 例外ユーザーかどうか
    isExemption: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    /**
     * @return {Boolean} 限定グループ設定の状態
     */
    isSetGroupSetting () {
      return this.$store.getters['settingGroup/isSetGroupSetting']
    },
    /**
     * @return {String} 限定グループ名
     */
    groupName () {
      return this.$store.getters['settingGroup/groupName']
    },
    /**
     * @return {Boolean} Discord連携しているかどうか
     */
    isDiscordLinkage () {
      return this.$store.getters['settingGroup/discordLinkage']
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";

.signup-warning {
  box-sizing: border-box;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  font-size: 1rem;
  list-style: none;
  &__text {
    line-height: 2rem;
    &::before {
      content: "※";
    }
    &__link {
      color: $green_color !important;
    }
  }
}
</style>
