<template>
  <div class="signup" v-if="!isProcessing">
    <h2 class="signup__title">{{ signupTitleText }}
      <p class="signup__title__price">
        &yen; <span class="signup__title__price__value">{{ price }}</span> /{{priceCycle}}（税込）
      </p>
    </h2>
    <p class="signup__message" v-if="!exemption">{{ signupMessageText }}</p>
    <features class="signup__features" v-if="!exemption" />
    <signup-button class="signup__btn" @show-warning="showWarning" />
    <warning class="signup__warning" :isExemption="!!exemption" />
    <sample-dialog ref="sample" />
  </div>
</template>

<script>
import Warning from '@/components/signup/warning.vue'
import Features from '@/components/signup/features.vue'
import SignupButton from '@/components/signup/button.vue'
import SampleDialog from '@/components/signup/sample/dialog.vue'

export default {
  name: 'signup',
  components: { Warning, Features, SignupButton, SampleDialog },
  async mounted () {
    // 表示チェック用の取得処理
    const checkPromises = []

    // 例外情報を取得する
    if (this.email && !this.isGotExemption) checkPromises.push(this.$store.dispatch('exemptions/getExemptionByEmail', this.email))
    // 招待コードの設定情報を取得する
    if (!this.existInvitationSetting) checkPromises.push(this.$store.dispatch('settingInvitation/getInvitationSetting'))

    await Promise.all(checkPromises)

    // 招待限定設定かつ例外情報がない(= 通常ユーザー)場合はtopへ飛ばす
    if (this.invitationLimited && !this.exemption) {
      this.$store.commit('setTelop', { show: true, msg: 'アカウント登録は招待限定です', type: 'warning' })
      this.$router.push({ name: 'top' })
    }

    // パラメータにemailが存在し、対応する例外情報がない場合はnot foundへ飛ばす
    if (this.email && !this.exemption) this.$router.push({ name: 'notfound' })

    const promises = []
    // グループ名を取得
    if (!this.groupName) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'groupName'))
    // Discord連携設定していない判定のときは最新の情報を取得する
    if (!this.isDiscordLinkage) promises.push(this.$store.dispatch('settingGroup/getGroupSetting', 'discordServerID'))
    // Signupのテキスト情報を取得
    if (!Object.keys(this.signupTextInfos).length) promises.push(this.$store.dispatch('settingSignups/getSettingSignups'))

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} 例外ユーザーの場合のメールアドレス
     */
    email () {
      return this.$route.query.email
    },
    /**
     * @return {Object} 例外情報
     */
    exemption () {
      return this.$store.getters['exemptions/exemption']
    },
    /**
     * @return {Boolean} 例外情報取得済みかどうか
     */
    isGotExemption () {
      return this.$store.getters['exemptions/isGot']
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} 限定グループ名
     */
    groupName () {
      return this.$store.getters['settingGroup/groupName']
    },
    /**
     * @return {Object} ロゴの画像
     */
    logoImage () {
      return this.$store.getters['settingImages/settingImage']('logo')
    },
    /**
     * @return {String} サービス名
     */
    serviceName () {
      return this.$store.getters['settingGeneral/settingGeneral']('serviceName').value
    },
    /**
     * @return {Object} Signupのテキスト情報
     */
    signupTextInfos () {
      return this.$store.getters['settingSignups/settingSignups']
    },
    /**
     * @return {Number} 月額料金
     */
    price () {
      return this.exemption ? 0 : Number(process.env.VUE_APP_PRICE).toLocaleString()
    },
    /**
     * @return {String} 月額料金
     */
    priceCycle () {
      return String(process.env.VUE_APP_PRICE_CYCLE)
    },
    /**
     * @return {String} Signupのタイトルテキスト
     */
    signupTitleText () {
      const title = this.signupTextInfos.messages ? this.signupTextInfos.messages.texts[0] : this.serviceName + 'に入会する'
      return this.exemption ? this.serviceName + '特別招待枠' : title
    },
    /**
     * @return {String} Signupのメッセージテキスト
     */
    signupMessageText () {
      return this.signupTextInfos.messages ? this.signupTextInfos.messages.texts[1] : ''
    },
    /**
     * @return {Boolean} 招待機能情報の存在有無
     */
    existInvitationSetting () {
      return this.$store.getters['settingInvitation/existSetting']
    },
    /**
     * @return {Boolean} 招待機能を利用し入会ユーザを招待限定とするか
     */
    invitationLimited () {
      return this.$store.getters['settingInvitation/limited']
    },
    /**
     * @return {Boolean} Discord連携しているかどうか
     */
    isDiscordLinkage () {
      return this.$store.getters['settingGroup/discordLinkage']
    }
  },
  methods: {
    /**
     * サンプルである旨を伝えるダイアログを開く
     */
    showWarning () {
      this.$refs.sample.open()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.signup {
  padding: $unit_size*7 $padding_width 0;
  margin-top: $header_height;
  &__title {
    font-size: 1.8rem;
    line-height: auto;
    text-align: center;
    &__price {
      margin: $unit_size*2 0 0;
      font-size: 1.8rem;
      font-weight: normal;
      &__value {
        font-size: 3.6rem;
        font-weight: bold;
      }
    }
  }
  &__message {
    margin: $unit_size*7 0 0;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: center;
  }
  &__features {
    margin-top: $unit_size * 4;
  }
  &__btn {
    margin-top: $unit_size * 8;
  }
  &__warning {
    margin-top: $unit_size * 7;
  }
}
</style>
