<template>
  <v-dialog class="signup-sample-dialog" v-model="dialog">
    <v-card class="signup-sample-dialog__area">
      <v-card-title class="signup-sample-dialog__area__title">アカウント作成できません</v-card-title>
      <v-card-text class="signup-sample-dialog__area__msg">
        サンプルページのためアカウント作成はできません。
        本来であれば、ここから基本情報の登録、クレジットカード情報のご登録画面へと遷移します。
      </v-card-text>
      <v-btn text class="signup-sample-dialog__area__btn" @click="doClose()">とじる</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null
    }
  },
  methods: {
    /**
     * ダイアログを開く
     * @return {Promise}
     */
    open () {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    /**
     * ダイアログを閉じる
     */
    doClose () {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.signup-sample-dialog {
  &__area {
    width: 100%;
    padding: 20px 15px;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    &__title {
      display: block;
      margin: 0;
      &.v-card__title {
        padding: 0;
        font-size: 1.4rem;
        color: $black_lighten_color;
      }
    }
    &__msg {
      margin: 10px 0 0;
      text-align: left;
      &.v-card__text {
        padding: 0;
        font-size: 1rem;
        line-height: 1.4rem;
        color: $black_lighten_color;
      }
    }
    &__btn {
      margin: 30px 30px 0;
      font-size: 1.4rem;
      font-weight: bold;
      color: $concept_color;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
        height: auto;
        padding: 0;
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

// vuetify用
.v-dialog {
  width: 60%;
  max-width: 300px;
  background-color: white;
  border-radius: 15px;
  box-shadow: none;
}

.v-overlay--active {
  .v-overlay__scrim {
    background-color: $black_lighten_color !important;
    border: none;
    opacity: 0.7 !important;
  }
}
</style>
