<template>
  <div class="signup-features">
    <div class="signup-features__item" v-for="(feature, index) in signupFeatureTexts" :key="feature + index">
      <div class="signup-features__item__number" />
      <div class="signup-features__item__text">{{ feature }}</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    /**
     * @return {Object} Signupのテキスト情報
     */
    signupTextInfos () {
      return this.$store.getters['settingSignups/settingSignups']
    },
    /**
     * @return {String[]} Signupの特徴テキスト配列
     */
    signupFeatureTexts () {
      // featuresを全部埋めていない場合もあるので、文字列が空の場合は配列から除外する
      return this.signupTextInfos.features ? this.signupTextInfos.features.texts.filter(text => !!text) : []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.signup-features {
  counter-reset: number 0;
  &__item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 1.4rem;
    line-height: auto;
    &__number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      font-weight: bold;
      color: $white_color;
      background-color: $concept_color;
      border-radius: 50%;
      &::before {
        content: counter(number) " ";
        counter-increment: number 1;
      }
    }
    &__text {
      flex: 1;
      margin: 0 0 0 10px;
    }
  }
}
</style>
